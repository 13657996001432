// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-articles-categories-[id]-js": () => import("./../../../src/pages/articles/categories/[id].js" /* webpackChunkName: "component---src-pages-articles-categories-[id]-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-work-js": () => import("./../../../src/pages/our-work.js" /* webpackChunkName: "component---src-pages-our-work-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-templates-blogs-js": () => import("./../../../src/templates/blogs.js" /* webpackChunkName: "component---src-templates-blogs-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/caseStudy.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/landingPage.js" /* webpackChunkName: "component---src-templates-landing-page-js" */)
}

